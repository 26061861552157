import './SplashScreen.css'
import edgeCovers from "../images/edgeCovers.png"
import React from "react"

function SplashScreen(props) {

    return (
        <div className="loadingBlock">
            <p className="loadingFocalFadingC"> C </p>
            <div className="fadeImageIn"> 
                <img className="loadingCircleImage" src={edgeCovers} alt="circle outline with two interupting rectangles" />
            </div>
        </div>
    )
}

export default SplashScreen;