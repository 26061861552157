import "./ActualPages.css";
import { Link } from "react-router-dom";
import CareerVisualizerBubbles from './components/CareerVisualizerBubbles.js'
import React from "react";


function ProjectVisualizer(props) {
  const yearList = ["2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025"]

  return (
    <div className="projectVisualizer">
        <Link className="link" to="/" >
            <p className="backButton"> back to home </p>
        </Link>
        <p className="headerText"> career progression  </p>
        <p className="headerSubText"> the traditional career path is linear - one job at a time, focusing and progressing in one specialty. this isn't the only option, and you're free to create your own path. I built this visualization to help illustrate what it means to have a non-linear career. each career item is clickable for more details!</p>
        <div className="visualizerRow">
            <div className="timeline">
                {yearList.map ((year, index) => (
                <div key={index} className="timelineYearTickRow">
                    <p className="timelineYear">{year}</p>
                    <div className="timelineTick"></div>
                </div>
                ))}
            </div>
            <div className="bubbles">
                    <CareerVisualizerBubbles />
            </div>
        </div>
        <p className="footerText"> the size of each circle represents the self-ranked importance factor i've given each element. there are 4 different sizes represented</p>
        <p className="footerText"> the timeline corresponds to the project start time frame, but does not indicate project end time (as i wanted to use circles to represent, not ovals or rectangles)</p>
        <p className="footerText"> important note: always ensure you are acting within your company's compliance rules and regulations. i've found success in focusing on learning stages while in corporate roles to avoid conflicts of interest.</p>
    </div>
  );
}

export default ProjectVisualizer;
