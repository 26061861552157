import "./Biography.css";

function Biography(props) {

    return (
        <div className="biography">
            <p className="biographyText"> hi and welcome to my website, a visual exploration of my work and personal projects. i've spent my career thus far exploring as many different topics and ideas as possible, through the lense of my 3 primary passions - designing, engineering, and innovating.</p>
        </div>
    );
}

export default Biography;

