import "../ActualPages.css";
import React from "react";

function ActiveBlog(props) {

  return (
    <div className="activeBlog">
        <p className="headerText"> {props.activeBlog.title} </p>
        <img src={props.activeBlog.mainImg} alt={props.activeBlog.imgDescription} className="blogFullImage" />
        <p className="activeBlogImgSubtext"> {props.activeBlog.imgDescription} </p>
        {props.activeBlog.paragraphs.map((paragraph, index) => (
            <p className="blogParagraph"> {paragraph} </p>
        ))}
    </div>
  );
}

export default ActiveBlog;
