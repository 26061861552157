import "./ActualPages.css";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import blogList from "../assets/constants/blogList";
import ActiveBlog from './components/ActiveBlog.js';
import blurMobile from '../assets/blurMobile.png';
import blurDesktop from '../assets/blurDesktop.png';


function Blog(props) {

  const [activeBlog, setActiveBlog] = useState(null)
  const blurClass = props.isMobile ? "blurImageMobile" : "blurImageDesktop";
  const blur = props.isMobile ? blurMobile : blurDesktop;

  return (
    <div className="blog">
      <img className={blurClass} src={blur} alt="hot pink gradient"></img>
      <Link className="link" to="/" >
        <p className="backButton"> back to home </p>
      </Link>
      {activeBlog !== null &&
      <div>
          <p onClick={() => setActiveBlog(null)} className="backButton bottomButton"> back to all blogs </p>
          <ActiveBlog activeBlog={blogList[activeBlog]} setActiveBlog={setActiveBlog} />
        </div>
      }
      {activeBlog === null &&
        <div>
          <p className="headerText"> blog  </p>
          <p className="headerSubText"> random thoughts and ideas i've explored. unstructured and posted infrequently</p>
          <div className="blogList">
            {blogList.map((blog, index) => (
              <div onClick={() => setActiveBlog(index)} className="individualBlog">
                <p className="blogTitle"> {blog.title} </p>
                <img src={blog.mainImg} alt={blog.imgDescription} className="blogPreviewImage" />
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  );
}

export default Blog;
