const blogList = [
  {
    title: "style guidelines for css code",
    mainImg: "https://live.staticflickr.com/65535/54279332186_7225d4aa1b_o.png",
    imgDescription: "plata, the greek goddess of css. image form generated with adobe firefly image 3, edited by me",
    paragraphs: [
        "css - the internet language of style. but why does the way we write style code have to be so ...unstyled?",
        "PLATA is an acronym for place, location, appearance, text, animation",
        "in dev - more coming soon!",
        ""
  ],
  },
  {
    title: "getting started with typography",
    mainImg: "https://live.staticflickr.com/65535/54279698528_83e3bb7a10_o.png",
    imgDescription: "three different fonts, upper case and lower case",
    paragraphs: [
        "in dev - more coming soon!",
  ],
  },
];

export default blogList;
