import "./CareerVisualizerBubbles.css";
import React from "react";


function CareerVisualizerBubbles() {

  return (
    <div className="careerVis">
        <div className="topRectangle"> 
            <p className="careerText"> graduated from Cornell University College of Engineering</p>
        </div>
        <div className="largeCareerCircle gsCircle"> 
            <p className="careerText largeCircleText"> software developer at Goldman Sachs</p>
        </div>
        <div className="smallCareerCircle graphicDesignCircle"> 
            <p className="careerText smallCircleText"> started learning graphic design</p>
        </div>
        <div className="smallCareerCircle appDevCircle"> 
            <p className="careerText smallCircleShortText"> started learning app dev</p>
        </div>
        <div className="smallCareerCircle productCircle"> 
            <p className="careerText smallCircleText"> started learning product dev</p>
        </div>
        <div className="mediumCareerCircle datedCircle"> 
            <p className="careerText mediumCircleText"> co-founded Dated</p>
        </div>
        <div className="largestCareerCircle ceriseCircle"> 
            <p className="careerText largestCircleText"> founded Cerise Technology</p>
        </div>
        <div className="mediumCareerCircle bbgCircle"> 
            <p className="careerText mediumCircleTextbbg"> product manager at Bloomberg</p>
        </div>
        <div className="smallCareerCircle goodseedCircle"> 
            <p className="careerText smallCircleText"> co-founded goodseed protein</p>
        </div>
        <div className="smallCareerCircle wsfCircle"> 
            <p className="careerText smallCircleText"> launched work smarter fitness</p>
        </div>
        <div className="mediumCareerCircle infCircle"> 
            <p className="careerText mediumCircleTextTall"> co-founded instant noodle factory</p>
        </div>
        <div className="smallCareerCircle emblCircle"> 
            <p className="careerText smallCircleText"> co-founded emblem atic</p>
        </div>
        <div className="largeCareerCircle byteCircle"> 
            <p className="careerText largeCircleText"> launched byte - kitchen software</p>
        </div>
        <div className="mediumCareerCircle oofCircle"> 
            <p className="careerText mediumCircleText"> launched one of fourteen</p>
        </div>
        <div className="smallCareerCircle noodlepediaCircle"> 
            <p className="careerText smallCircleShortText"> launched noodle pedia</p>
        </div>
    </div>
  );
}

export default CareerVisualizerBubbles;
