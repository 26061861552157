import jobsTag from "./images/jobsTag.png"
import vcTag from "./images/vcTag.png"
import designedTag from "./images/designedTag.png"
import appTag from "./images/appTag.png"
import codeTag from "./images/codeTag.png"

const jobs = {
    "src": jobsTag,
    "alt": "small icon depicting a computer job search"
}

const vc = {
    "src": vcTag,
    "alt": "small icon depicting a monitary investment from a vc fund"
}

const designed = {
    "src": designedTag,
    "alt": "small icon depicting a website layout design"
}

const app = {
    "src": appTag,
    "alt": "small icon depicting a mobile phone with a heart icon"
}

const code = {
    "src": codeTag,
    "alt": "small icon depicting a gear icon on a desktop window"
}

const projectList = 
    [
        {
            "title": "Noodlepedia",
            "tags": [2, 3, 9, 5, 6],
            "description": "mobile instant noodle rating app",
            "moreDetails": "a comprehensive encyclopedia of every single instant noodles, with ratings, recipes, and more",
            "href": "https://inoodlefactory.com/",
            "engTag": true,
            "entrepTag": true,
            "designTag": true,
            "iconTags": [designed, code, jobs]
        },
        {
            "title": "Instant Noodle Factory",
            "tags": [2, 3, 9, 5, 6],
            "description": "fast casual instant noodle concept",
            "moreDetails": "a build-your-own instant noodle bowl shop with two locations in NYC",
            "href": "https://inoodlefactory.com/",
            "engTag": true,
            "entrepTag": true,
            "designTag": true,
            "iconTags": [designed, code, jobs]
        },
        {
            "title": "Byte",
            "tags": [1, 2, 3, 9, 5, 6],
            "description": "smarter restaurant kitchen software",
            "moreDetails": "ai generated insights for restaurants to optimize operations and maximize sales",
            "href": "https://www.byte-smarterkitchen.com/",
            "engTag": true,
            "entrepTag": true,
            "designTag": true,
            "iconTags": [designed, code, jobs, vc]
        },
        {
            "title": "Eleve",
            "tags": [1, 2, 3, 9, 5, 6],
            "description": "bespoke high tech for tasting menus",
            "moreDetails": "software solutions for michelin star restaurants to better connect with their customers",
            "href": "https://www.byte-smarterkitchen.com/eleve",
            "engTag": true,
            "entrepTag": true,
            "designTag": true,
            "iconTags": [designed, code, jobs, vc]
        },
        {
            "title": "GoodSeed",
            "tags": [4, 9, 5],
            "description": "premium hemp seed protein powder",
            "moreDetails": "a better vegan protein powder - with high-purity dehulled hemp seed protein",
            "href": "https://goodseedprotein.com/",
            "engTag": false,
            "entrepTag": true,
            "designTag": true,
            "iconTags": [designed, jobs, vc]
        },
        {
            "title": "Work Smarter Fitness",
            "tags": [1, 2, 3, 9, 5, 4],
            "description": "progressive overload instructed by ai",
            "moreDetails": "a minimalistic application that utilizes machine learning to optimize workouts",
            "href": "https://worksmarterfitness.com/",
            "engTag": true,
            "entrepTag": false,
            "designTag": true,
            "iconTags": [designed, code, app]
        },
        {
            "title": "AIDA",
            "tags": [1, 3, 5, 6],
            "description": "artificial intelligence digital aesthetic",
            "moreDetails": "a study examining ai's ability to pull current pintrest trends and produce original aesthetic content",
            "href": "https://aidigitialaesthetics.com",
            "engTag": true,
            "entrepTag": false,
            "designTag": true,
            "iconTags": [designed, code]
        },
        // {
        //     "title": "Big Sugar",
        //     "tags": [4, 5],
        //     "description": "llskdjfalksfjd",
        //     "href": "bigsugarpodcast.com",
        //     "engTag": true,
        //     "entrepTag": true,
        //     "designTag": true
        // },
        {
            "title": "Dated",
            "tags": [1, 2, 3, 5, 6],
            "description": "centralized database for online dating",
            "moreDetails": "my first startup - an ios/android app that helped users of online dating products date smarter",
            "href": "https://cierrabeck.com/dated",
            "engTag": true,
            "entrepTag": true,
            "designTag": true,
            "iconTags": [designed, code, app, vc]
        },
        {
            "title": "one of fourteen",
            "tags": [1, 2, 3, 4, 5, 8],
            "description": "productivity and wellness software",
            "moreDetails": "applying product management techniques to daily life, balancing self care and deep focus",
            "href": "http://oneoffourteen.com/landing",
            "engTag": true,
            "entrepTag": false,
            "designTag": true,
            "iconTags": [designed, code]
        },
        {
            "title": "Backtrack",
            "tags": [5, 6],
            "description": "sample use case of the spotify api",
            "moreDetails": "fun spotify project illustrating the power of the spotify api",
            "href": "backtrack.surge.sh",
            "engTag": true,
            "entrepTag": false,
            "designTag": true,
            "iconTags": [designed, code]
        },
        // {
        //     "title": "Athena Fellowship",
        //     "tags": [2, 7, 8],
        //     "description": "mentor for the girls in stem program",
        //     "moreDetails": "I held educational sessions on product design, entrepreneurship, and productivity",
        //     "href": "https://www.athenafellowship.org/",
        //     "engTag": true,
        //     "entrepTag": true,
        //     "designTag": true,
        //     "iconTags": []
        // },
        // {
        //     "title": "Isabella project",
        //     "tags": [1, 2, 7],
        //     "description": "llskdjfalksfjd",
        //     "href": "backtrack.surge.sh",
        //     "engTag": true,
        //     "entrepTag": false,
        //     "designTag": true
        // },
        // {
        //     "title": "Aiwen project",
        //     "tags": [1, 7],
        //     "description": "llskdjfalksfjd",
        //     "href": "backtrack.surge.sh",
        //     "engTag": true,
        //     "entrepTag": false,
        //     "designTag": false
        // },
        // {
        //     "title": "open source fonts",
        //     "tags": [3, 5],
        //     "description": "selection of my custom fonts",
        //     "moreDetails": "a good font is essential to a good design, and sometimes you just have to create something custom",
        //     "href": "https://cierra-beck.com/fonts",
        //     "engTag": false,
        //     "entrepTag": false,
        //     "designTag": true,
        //     "iconTags": [designedTag]
        // },
    ]

export default projectList